import React from 'react';
import './LandingPage.css';

const LandingPage = () => {
  // Array of background images
  const backgrounds = [1, 2, 3, 4];

  // Randomly pick one background
  const randomBackground = backgrounds[Math.floor(Math.random() * backgrounds.length)];

  return (
    <div className="landing-container">
      {/* Logo */}
      <img src={`${process.env.PUBLIC_URL}/logo_square.png`} alt="Logo" className="landing-logo" />

      {/* Menu Portal */}
      <div className="menu-portal-container">
        <img src={`${process.env.PUBLIC_URL}/Menu_Portal.png`} alt="Menu Portal" className="menu-portal" />
      </div>

      {/* Background Image */}
      <div className="bg-container">
        <img
          src={`${process.env.PUBLIC_URL}/bg_${randomBackground}.jpg`}
          alt={`Background ${randomBackground}`}
          className="background-image"
        />
      </div>

      {/* Coming Soon Text */}
      <h1 className="coming-soon">Coming Soon...</h1>
    </div>
  );
};

export default LandingPage;
